import { createTheme } from "@mui/material";

export const primaryAccentColor = "#8ECDED";
export const secondaryAccentColor = "#0D1A42";
export const textColor = "#222222";
export const cardsColor = "#FFFFFF";
export const backgroundColor = "#F7F8F8";

const Theme = createTheme({
  palette: {
    primary: {
      main: primaryAccentColor,
    },
    secondary: {
      main: secondaryAccentColor,
    },
    info: {
      main: secondaryAccentColor,
    },
  },
  components: {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: "1em",
          backgroundColor: "rgba(0,0,0,0.7)",
          maxWidth: "400px",
        },
        arrow: {
          color: "rgba(0,0,0,0.7)",
        },
      },
    },
    MuiButton: {
      defaultProps: {
        variant: "contained",
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: "outlined",
      },
    },
  },
});

export default Theme;

import { FC } from "react";
import "../styles/abCenterCard.css";

const AbDownloadApp: FC = () => (
  <>
    <a href="https://apps.apple.com/fr/app/club-1906/id1635996878" className="download__link">
      <img src="/appStore.png" alt="Disponible sur App Store" className="download__img" />
    </a>
    <a href="https://play.google.com/store/apps/details?id=fr.abrugby.partners" className="download__link">
      <img src="/playStore.png" alt="Disponible sur Google Play" className="download__img" />
    </a>
  </>
);

export default AbDownloadApp;

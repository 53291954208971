import { FC, useEffect, useState } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import AbCenteredContent from "../components/AbCenteredContent";
import "./App.css";
import Navigation from "./Navigation";

const App: FC = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(false);
  }, []);

  return loading ? (
    <div className="App">
      <AbCenteredContent loading />
    </div>
  ) : (
    <Router>
      <Navigation />
    </Router>
  );
};

export default App;

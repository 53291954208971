import { FC } from "react";
import { useTranslation } from "react-i18next";
import "../styles/abCenterCard.css";

const AbCenterCard: FC<{ children: JSX.Element }> = ({ children }) => {
  const { t } = useTranslation("global");

  return (
    <div className="card__container">
      <img src="/logo.png" alt="ab logo" className="card__logo" />
      <h4 className="card__title">{t("appName")}</h4>
      {children}
    </div>
  );
};

export default AbCenterCard;

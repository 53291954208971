import { Visibility, VisibilityOff } from "@mui/icons-material";
import { FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput, TextField } from "@mui/material";
import { FC, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AuthContext } from "../data/contexts/AuthContext";
import { validateEmail } from "../data/types/TechnicalTypes";
import AbButton from "./AbButton";
import AbCenteredContent from "./AbCenteredContent";
import "../styles/signIn.css";

const SignIn: FC = () => {
  const [login, setLogin] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [signingIn, setSigningIn] = useState(false);
  const [initializing, setInitializing] = useState(true);
  const { authenticate, initLocalAuth } = useContext(AuthContext);
  const { t } = useTranslation("global");

  useEffect(() => {
    (async () => {
      await initLocalAuth();
      setInitializing(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const signIn = async (): Promise<void> => {
    setSigningIn(true);
    await authenticate(login, password);
    setSigningIn(false);
  };

  return initializing ? (
    <AbCenteredContent loading />
  ) : (
    <div className="sign-in-page">
      <div className="sign-in-root">
        <div className="sign-in-form">
          <TextField
            fullWidth
            label={t("authenticate.email")}
            value={login}
            onChange={(e) => setLogin(e.target.value)}
            className="margin-bottom"
            style={{ backgroundColor: "white", borderRadius: "10px" }}
            error={login.length > 0 && !validateEmail(login)}
          />
          <FormControl
            variant="outlined"
            className="big-margin-bottom"
            fullWidth
            style={{ backgroundColor: "white", borderRadius: "10px" }}>
            <InputLabel htmlFor="outlined-adornment-password">{t("authenticate.password")}</InputLabel>
            <OutlinedInput
              id="outlined-adornment-password"
              type={showPassword ? "text" : "password"}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowPassword(!showPassword)}
                    edge="end">
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              label={t("authenticate.password")}
            />
          </FormControl>
          <AbButton
            color="primary"
            label={t("authenticate.connect")}
            fullWidth
            isLoading={signingIn}
            onClick={signIn}
            disabled={login.length === 0 || password.length === 0 || !validateEmail(login)}
          />
        </div>
      </div>
    </div>
  );
};

export default SignIn;

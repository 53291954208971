import { FC, createContext, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import jwt, { JwtPayload } from "jwt-decode";
import { AuthData, AuthResponse } from "../types/ApiTypes";
import { getNewToken, postAuthentication } from "../Api";
import { log } from "../Logger";

const AUTH_STORE = "AUTH_STORE";

export interface Authentication {
  authResponse: AuthResponse;
  user: AuthData;
  accessExpires: number;
  refreshExpires: number;
}

export interface AuthContextProps {
  auth: Authentication | null;
  authenticate: (login: string, password: string) => Promise<void>;
  getToken: () => Promise<string | null>;
  signOut: () => void;
  initLocalAuth: () => Promise<string | null>;
}

export const AuthContext = createContext<AuthContextProps>({
  auth: null,
  authenticate: () => Promise.resolve(),
  getToken: () => Promise.resolve(null),
  signOut: () => {},
  initLocalAuth: () => Promise.resolve(null),
});

export const AuthProvider: FC<{ children: JSX.Element }> = ({ children }) => {
  const [auth, setAuth] = useState<Authentication | null>(null);
  const { t } = useTranslation("global");
  const { enqueueSnackbar } = useSnackbar();

  const contextValue = useMemo(() => {
    const handleResponse = (authResponse: AuthResponse, persist: boolean = true): Authentication => {
      const refresh = jwt(authResponse.refresh_token) as JwtPayload;
      const user = jwt(authResponse.access_token) as AuthData & JwtPayload;
      const result: Authentication = {
        authResponse,
        user,
        accessExpires: user.exp || 0,
        refreshExpires: refresh.exp || 0,
      };
      if (persist) {
        localStorage.setItem(AUTH_STORE, JSON.stringify(authResponse));
        setAuth(result);
      }
      return result;
    };
    const wipeData = (): void => {
      localStorage.removeItem(AUTH_STORE);
      setAuth(null);
    };
    const handleError = (result: number | AuthResponse): void => {
      wipeData();
      enqueueSnackbar(
        t(
          (result as AuthResponse).rejection_type
            ? "notCurrentSeason"
            : result === 401
            ? "wrongCredentials"
            : "unkownError",
        ),
        { variant: "error" },
      );
    };

    const authenticate = async (login: string, password: string): Promise<void> => {
      const result = await postAuthentication(login, password);
      if (typeof result === "object" && !Object.hasOwn(result, "rejection_type"))
        handleResponse(result as AuthResponse);
      else handleError(result);
    };

    const refreshTokenExpired = (): null => {
      enqueueSnackbar(t("tokenExpired"), { variant: "error" });
      wipeData();
      return null;
    };

    const getToken = async (inAuthResponse?: AuthResponse): Promise<string | null> => {
      const finalAuth = inAuthResponse ? handleResponse(inAuthResponse, false) : auth;
      if (!finalAuth?.authResponse || finalAuth?.authResponse.refresh_token.length === 0)
        throw new Error("No refresh token");

      // If access token expires in more than 10 seconds
      if (finalAuth.accessExpires > new Date().getTime() / 1000 + 10) {
        // Set in state if access token is valid and we are initializing
        if (inAuthResponse) {
          setAuth(finalAuth);
        }

        return finalAuth.authResponse.access_token;
      }

      // If refresh token is expired or expires in less than 10 seconds
      if (finalAuth.refreshExpires < new Date().getTime() / 1000 + 10) return refreshTokenExpired();

      const result = await getNewToken(finalAuth.authResponse.refresh_token);
      if (typeof result === "object") {
        log.debug("Just successfully refreshed token!");
        handleResponse(result as AuthResponse);
        return result.access_token;
      }
      return refreshTokenExpired();
    };

    const signOut = (): void => wipeData();

    const initLocalAuth = async (): Promise<string | null> => {
      const local = localStorage.getItem(AUTH_STORE);
      if (local) {
        return getToken(JSON.parse(local) as AuthResponse);
      }
      return null;
    };

    return {
      auth,
      authenticate,
      getToken,
      signOut,
      initLocalAuth,
    };
  }, [auth, enqueueSnackbar, t]);

  return <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>;
};

import { CircularProgress, Typography } from "@mui/material";
import { FC } from "react";

interface CenteredContentProps {
  children?: JSX.Element;
  loading?: boolean;
  errorText?: string;
  infoText?: string;
  className?: string;
  loadingSize?: number;
  normalText?: boolean;
  loadingColor?: "inherit" | "secondary" | "primary" | "error" | "info" | "success" | "warning";
}

const AbCenteredContent: FC<CenteredContentProps> = ({
  loading,
  errorText,
  infoText,
  children,
  className,
  loadingSize,
  normalText,
  loadingColor,
}) => (
  <div className={className ? `centered-content-root ${className}` : "centered-content-root"}>
    {loading ? (
      <CircularProgress color={!loadingColor ? "secondary" : loadingColor} size={loadingSize} />
    ) : (
      children || (
        <Typography variant={normalText ? undefined : "h6"} style={{ whiteSpace: "pre-line", textAlign: "center" }}>
          {errorText || infoText}
        </Typography>
      )
    )}
  </div>
);

AbCenteredContent.defaultProps = {
  children: undefined,
  loading: false,
  errorText: undefined,
  infoText: undefined,
  className: undefined,
  loadingSize: undefined,
  normalText: undefined,
  loadingColor: undefined,
};

export default AbCenteredContent;

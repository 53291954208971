export interface WithChildren {
  children?: JSX.Element;
}

export interface WithMandatoryChildren {
  children: JSX.Element;
}

export interface WithClassName {
  className?: string;
}

export const validateEmail = (email: string): boolean => {
  return (
    String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      ) !== null
  );
};

export const dedupArray = (input: string[]): string[] => input.filter((v, i) => input.indexOf(v) === i);

import { FC } from "react";
import { Route, Routes } from "react-router-dom";
import { ConditionsType } from "../data/types/ApiTypes";
import Conditions from "../pages/Conditions";
import Home from "../pages/Home";
import UserPasswordPage from "../pages/UserPasswordPage";
import TicketsPage from "../pages/TicketsPage";

const Navigation: FC = () => {
  return (
    <Routes>
      <Route path="/billets" element={<TicketsPage />} />
      <Route path="/password/:userId/:correlationId" element={<UserPasswordPage />} />
      <Route path="/confidentialite" element={<Conditions conditionsType={ConditionsType.privacy_policy} />} />
      <Route path="/cgu" element={<Conditions conditionsType={ConditionsType.cgu} />} />
      <Route path="*" element={<Home />} />
    </Routes>
  );
};

export default Navigation;

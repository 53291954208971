import { useSnackbar } from "notistack";
import { createContext, FC, useContext, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { getAppConfig, getPartnersWebData, getTicketPdfBlob, getTicketsArchiveBlob } from "../Api";
import { log } from "../Logger";
import { Conditions, Match, PartnerTicket } from "../types/ApiTypes";
import { AuthContext } from "./AuthContext";
import { downloadFile } from "../Converters";

export interface DataContextProps {
  conditions: Conditions[];
  initApp: () => Promise<void>;
  matches: Match[];
  tickets: PartnerTicket[];
  getPartnerWebData: () => Promise<void>;
  downloadTicket: (ticketId: number, fileName: string) => Promise<void>;
  downloadTicketsArchive: (matchId: string, fileName: string, category?: string) => Promise<void>;
  latestTicketsFetch: number;
}

export const DataContext = createContext<DataContextProps>({
  conditions: [],
  initApp: () => Promise.resolve(),
  matches: [],
  tickets: [],
  getPartnerWebData: () => Promise.resolve(),
  downloadTicket: () => Promise.resolve(),
  downloadTicketsArchive: () => Promise.resolve(),
  latestTicketsFetch: 0,
});

export const DataProvider: FC<{ children: JSX.Element }> = ({ children }) => {
  const [conditions, setConditions] = useState<Conditions[]>([]);
  const [matches, setMatches] = useState<Match[]>([]);
  const [tickets, setTickets] = useState<PartnerTicket[]>([]);
  const [latestTicketsFetch, setLatestTicketsFetch] = useState(0);
  const { t } = useTranslation("global");
  const { enqueueSnackbar } = useSnackbar();
  const { getToken } = useContext(AuthContext);

  const contextValue = useMemo(() => {
    const handleError = (e: Error): void => {
      log.error("Error while getting all data", e);
      enqueueSnackbar(t("unkownError"), { variant: "error" });
    };

    const initApp = async (): Promise<void> => {
      try {
        log.debug("Getting config...");
        const config = await getAppConfig();
        log.debug("Config:", config);
        if (typeof config === "number") {
          handleError(new Error(`Server responded with status ${config}`));
        } else {
          setConditions(config.latestConditions);
        }
        return;
      } catch (e) {
        handleError(e as Error);
      }
    };

    const getPartnerWebData = async (): Promise<void> => {
      const token = await getToken();
      const result = await getPartnersWebData(token || "");
      if (typeof result === "object") {
        setMatches(result.matches);
        setTickets(result.tickets);
        setLatestTicketsFetch(new Date().getTime());
      }
    };

    const downloadTicket = async (ticketId: number, fileName: string): Promise<void> => {
      const token = await getToken();
      const result = await getTicketPdfBlob(token || "", ticketId);
      if (typeof result === "number") {
        enqueueSnackbar(t("downloadError"), { variant: "error" });
      } else {
        downloadFile(result, fileName);
      }
    };

    const downloadTicketsArchive = async (matchId: string, fileName: string, category?: string): Promise<void> => {
      const token = await getToken();
      const result = await getTicketsArchiveBlob(token || "", matchId, category);
      if (typeof result === "number") {
        enqueueSnackbar(t("downloadError"), { variant: "error" });
      } else {
        downloadFile(result, fileName);
      }
    };

    return {
      conditions,
      initApp,
      matches,
      tickets,
      getPartnerWebData,
      downloadTicket,
      downloadTicketsArchive,
      latestTicketsFetch,
    };
  }, [conditions, enqueueSnackbar, getToken, matches, tickets, t, latestTicketsFetch]);

  return <DataContext.Provider value={contextValue}>{children}</DataContext.Provider>;
};

import { log } from "./Logger";

export const formatDate = (d: Date | number | string): string => {
  const date = new Date(typeof d === "number" ? d * 1000 : d);
  return date.toLocaleDateString();
};

export const formatShortestDate = (d?: Date | number | string): string => {
  const date = d ? new Date(typeof d === "number" ? d * 1000 : d) : new Date();
  const year = date.getFullYear().toString();
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");
  return `${year}${month}${day}`;
};

export const formatShortDate = (d: Date | number | string): string => {
  const date = new Date(typeof d === "number" ? d * 1000 : d);
  const year = date.getFullYear().toString().substring(2, 4);
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");
  return `${day}/${month}/${year}`;
};

export const formatLongDate = (d: Date | number | string, dayName = false, withTime = false): string => {
  const date = new Date(typeof d === "number" ? d * 1000 : d);
  const months = [
    "Janvier",
    "Février",
    "Mars",
    "Avril",
    "Mai",
    "Juin",
    "Juillet",
    "Août",
    "Septembre",
    "Octobre",
    "Novembre",
    "Décembre",
  ];
  const days = ["Dimanche", "Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi"];
  return `${dayName ? `${days[date.getDay()]} ` : ""}${date.getDate() === 1 ? "1er" : date.getDate()} ${
    months[date.getMonth()]
  } ${date.getFullYear()}${
    withTime ? `, ${date.getHours().toString().padStart(2, "0")}h${date.getMinutes().toString().padStart(2, "0")}` : ""
  }`;
};

export const getTodayTimestamp = (): number => {
  const today = new Date();
  today.setHours(12, 0, 0, 0);
  return Math.round(today.getTime() / 1000);
};

export const downloadFile = (content: Blob, fileName: string): boolean => {
  try {
    const finalUrl = window.URL.createObjectURL(content);
    const link = document.createElement("a");
    link.href = finalUrl;
    link.target = "_blank";
    link.setAttribute("download", fileName);
    link.setAttribute("style", "display: none");
    document.body.appendChild(link);
    link.click();
    return true;
  } catch (reason) {
    log.error(reason);
    return false;
  }
};

export interface AuthData {
  userid: string;
  username: string;
  admin: boolean;
  partnerId: string;
  referent: boolean;
}

export interface AuthResponse {
  access_token: string;
  refresh_token: string;
  rejection_type?: string;
}

export interface Partner {
  id: string;
  listed: boolean;
  name: string;
  logo?: string;
  city?: string;
  sector?: string;
  email?: string;
  website?: string;
}

export interface UserAcceptance {
  conditionsId: string;
  date: number;
  application: string;
}

export interface User {
  id: string;
  referent: boolean;
  username: string;
  partnerId: string;
  firstName: string;
  lastName: string;
  acceptances?: UserAcceptance[];
}

export interface Picture {
  url: string;
}

export interface Article {
  id: string;
  active?: boolean;
  creationDate?: number;
  title: string;
  subtitle?: string;
  body: string;
  pictures: Picture[];
  published: boolean;
  publicationDate: number;
}

export interface Place {
  name?: string;
  address?: string;
  zipCode?: string;
  city?: string;
}

export enum EventType {
  business = "business",
  match = "match",
}

export enum EventQuestionType {
  value = "value",
  choice = "choice",
}

export interface EventQuestion {
  question?: string;
  type?: EventQuestionType;
  choices?: string[];
}

export interface EventAnswer {
  question: string;
  answer: string;
}

export interface EventOptionRegistration {
  optionId: number;
  attendees: string[];
  answers: EventAnswer[];
}

export interface EventOption {
  id: number;
  title: string;
  registrationsLimit?: number;
  times?: string;
  questions?: EventQuestion[];
  maxAttendees?: number;
}

export interface EventProgram {
  limitDate?: number;
  options?: EventOption[];
}

export interface Event {
  id: string;
  active?: boolean;
  creationDate?: number;
  type: EventType;
  title: string;
  body?: string;
  pictures?: Picture[];
  published: boolean;
  eventDate: number;
  place?: Place;
  program?: EventProgram;
  nota?: string;
}

export interface Message {
  id: string;
  senderId: string;
  body: string;
  sent: number;
  usersRead: string[];
}

export interface Conversation {
  id: string;
  usersId: string[];
  messages: Message[];
}

export interface Team {
  id: string;
  name: string;
  logo?: string;
  stadium?: Place;
}

export enum Championship {
  top14 = "top14",
  proD2 = "proD2",
  europeChallenge = "europeChallenge",
  europeChampions = "europeChampions",
  amical = "amical",
}

export interface Match {
  id: string;
  active?: boolean;
  title?: string;
  body?: string;
  pictures?: Picture[];
  background?: string;
  published?: boolean;
  date?: number;
  time?: string;
  home?: boolean;
  place?: Place;
  opponent?: Team;
  championship?: Championship;
  score?: string;
  ticketsLink?: string;
  championshipDay?: number;
  season?: number;
  damFolderPath?: string;
  parkingTicketsCode?: string;
}

export interface AllData {
  user: User;
  userPartners: Partner[];
  partners: Partner[];
  news: Article[];
  conversations: Conversation[];
  events: Event[];
  matches: Match[];
}

export interface AppConfig {
  latestVersion: string;
  latestConditions: Conditions[];
}

export enum ConditionsType {
  cgu = "cgu",
  privacy_policy = "privacy_policy",
}

export interface Conditions {
  id: string;
  type?: ConditionsType;
  version?: number;
  body?: string;
  published?: boolean;
}

export interface PartnerTicket {
  entryId: number;
  eventDate?: string;
  seat: {
    category: string | null;
    entrance: string | null;
    section: string | null;
    rank: string | null;
    number: string | null;
  } | null;
  holder: { id?: string | null; firstName?: string | null; lastName?: string | null } | null;
  title: string;
  url: string;
  digitickUrl?: string;
  ticketId?: number;
  orderId?: number;
  matchId?: string;
  barcode?: string;
  error?: string;
}

export interface PartnerWebData {
  matches: Match[];
  tickets: PartnerTicket[];
}

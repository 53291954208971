import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import "../styles/staticBanner.css";

const StaticBanner: FC = () => {
  const { t } = useTranslation("ticket");

  return (
    <div className="banner margin-bottom">
      <Link to="/billets">
        <img
          className="banner__picture"
          src="https://res.cloudinary.com/abrugby/image/upload/v1697810644/t_HeroDesktop/billetterie/AFFICHE_40x60_AB-ABO23-24.png"
          alt=""
        />
        <div className="banner__overlay" />
        <div className="banner__contentContainer" style={{ alignItems: "flex-end" }}>
          <h3 className="banner__title">{t("title")}</h3>
        </div>
      </Link>
    </div>
  );
};

export default StaticBanner;

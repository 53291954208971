import { FC } from "react";
import { useTranslation } from "react-i18next";
import AbCenterCard from "../components/AbCenterCard";
import AbDownloadApp from "../components/AbDownloadApp";
import StaticBanner from "../components/Staticbanner";
import "../styles/abCenterCard.css";

const Home: FC = () => {
  const { t } = useTranslation("global");
  return (
    <div className="App">
      <AbCenterCard>
        <>
          <StaticBanner />
          <h6 className="card__subtitle">{t("installApp")}</h6>
          <AbDownloadApp />
        </>
      </AbCenterCard>
    </div>
  );
};

export default Home;

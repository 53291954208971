import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import frGlobal from "./fr/global.json";
import frProfile from "./fr/profile.json";
import frPartner from "./fr/partner.json";
import frEvent from "./fr/event.json";
import frMatch from "./fr/match.json";
import frTicket from "./fr/ticket.json";

i18n.use(initReactI18next).init({
  resources: {
    fr: {
      global: frGlobal,
      profile: frProfile,
      partner: frPartner,
      event: frEvent,
      match: frMatch,
      ticket: frTicket,
    },
  },
  lng: "fr",
  fallbackLng: "fr",
  interpolation: {
    escapeValue: false,
  },
});

enum ApiActions {
  authenticate = "authenticate",
  refreshToken = "refreshToken",
  getAllData = "getAllData",
  getConfig = "getConfig",
  userAcceptConditions = "userAcceptConditions",
  userCheckCorrelation = "userCheckCorrelation",
  userSetPassword = "userSetPassword",
  getPartnersWebData = "getPartnersWebData",
  allTicketsForMatch = "allTicketsForMatch",
  getTicketPdf = "getTicketPdf",
}

export default ApiActions;

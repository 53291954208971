import { createRef, FC, useEffect } from "react";
import { CssBaseline, IconButton, ThemeProvider } from "@mui/material";
import { Close } from "@mui/icons-material";
import { SnackbarKey, SnackbarProvider } from "notistack";
import "./i18n";
import Theme from "./Theme";
import { DataProvider } from "../data/contexts/DataContext";
import { AuthProvider } from "../data/contexts/AuthContext";

const notistackRef = createRef<SnackbarProvider>();
const onClickDismiss = (key: SnackbarKey) => () => {
  notistackRef.current?.closeSnackbar(key);
};
const SnackBarCloser = (key: SnackbarKey): JSX.Element => (
  <IconButton onClick={onClickDismiss(key)}>
    <Close />
  </IconButton>
);

const Wrapper: FC<{ children: JSX.Element }> = ({ children }) => {
  const axeptioId = process.env.REACT_APP_AXEPTIO_ID || "";
  useEffect(() => {
    if (axeptioId.length > 0) {
      const el = document.createElement("script");
      el.setAttribute("src", "https://static.axept.io/sdk-slim.js");
      el.setAttribute("type", "text/javascript");
      el.setAttribute("async", "true");
      el.setAttribute("data-id", axeptioId);
      if (document.body !== null) {
        document.body.appendChild(el);
      }
    }
  }, [axeptioId]);
  return (
    <ThemeProvider theme={Theme}>
      <CssBaseline />
      <SnackbarProvider
        ref={notistackRef}
        maxSnack={3}
        anchorOrigin={{ horizontal: "center", vertical: "top" }}
        preventDuplicate
        autoHideDuration={10000}
        action={SnackBarCloser}>
        <AuthProvider>
          <DataProvider>{children}</DataProvider>
        </AuthProvider>
      </SnackbarProvider>
    </ThemeProvider>
  );
};

export default Wrapper;

import { Download, ExpandMore } from "@mui/icons-material";
import { Accordion, AccordionDetails, AccordionSummary, IconButton, Tooltip } from "@mui/material";
import { FC, MouseEvent, SyntheticEvent, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { formatLongDate } from "../data/Converters";
import { DataContext } from "../data/contexts/DataContext";
import { Match } from "../data/types/ApiTypes";
import "../styles/tickets.css";
import AbCenteredContent from "./AbCenteredContent";
import CategoriesList from "./CategoriesList";
import ZipLoader from "./ZipLoader";
import AbButton from "./AbButton";

const presentationAccordionId = "presentation";

const Tickets: FC = () => {
  // const [show, setShow] = useState<Record<string, boolean>>({});
  const [downloading, setDownloading] = useState<Record<string, boolean>>({});
  const [expanded, setExpanded] = useState<string | false>();
  const { matches, tickets, downloadTicketsArchive, latestTicketsFetch } = useContext(DataContext);
  const { t } = useTranslation("ticket");

  const handleChange = (panel: string) => (_: SyntheticEvent, newExpanded: boolean) => {
    setExpanded(newExpanded ? panel : false);
  };

  const dlTickets = (e: MouseEvent<HTMLButtonElement>, match: Match): boolean => {
    e.stopPropagation();
    setTimeout(() => {
      setDownloading({ ...downloading, [match.id]: true });
      downloadTicketsArchive(match.id, `Billets_${(match.title || "").replace(/ /g, "")}.zip`).then(() =>
        setDownloading({ ...downloading, [match.id]: false }),
      );
    }, 100);
    return false;
  };

  const dlPresTickets = (e: MouseEvent<HTMLButtonElement>): boolean => {
    e.stopPropagation();
    setTimeout(() => {
      setDownloading({ ...downloading, [presentationAccordionId]: true });
      downloadTicketsArchive(presentationAccordionId, `Billets_PresentationEffectif.zip`).then(() =>
        setDownloading({ ...downloading, [presentationAccordionId]: false }),
      );
    }, 100);
    return false;
  };

  const openAvantages = (): void => {
    window.open("https://billetterie-entreprise.abrugby.fr/account/login");
  };

  const presentationTickets = tickets?.filter((ti) => ti.eventDate?.startsWith("2024-08-28"));
  const atLeastOnePresentation = (presentationTickets?.length || 0) > 0;

  return (
    <div className="tickets__container">
      <div className="tickets__avantagesButton">
        <AbButton onClick={openAvantages} label="AVANTAGES" />
      </div>
      {matches.length === 0 ? (
        latestTicketsFetch === 0 ? (
          <AbCenteredContent loading />
        ) : (
          <AbCenteredContent infoText={t("noTicketAtAll")} />
        )
      ) : (
        <>
          {atLeastOnePresentation ? (
            <Accordion
              className="tickets__accordion"
              expanded={expanded === presentationAccordionId}
              onChange={handleChange(presentationAccordionId)}
              key={presentationAccordionId}>
              <AccordionSummary sx={{ display: "flex", justifyContent: "space-between" }} expandIcon={<ExpandMore />}>
                <div className="tickets__match__container">
                  <div className="tickets__match__container__infos">
                    <h6 className="tickets__match__container__infos__title">
                      Présentation de l&apos;effectif 2024/2025
                    </h6>
                    <div className="tickets__match__container__infos__body">
                      {t("dateFormat", {
                        replace: { date: formatLongDate("2024-08-28" || "", true), hour: "18H30" },
                      })}
                    </div>
                  </div>
                  <div className="tickets__match__container__tickets">
                    {t("ticket", {
                      count: presentationTickets?.length,
                    })}
                  </div>
                  <Tooltip title={t("downloadMatchTickets")} arrow>
                    <IconButton
                      onClick={(e) => dlPresTickets(e)}
                      disabled={!atLeastOnePresentation || downloading[presentationAccordionId]}>
                      <Download />
                    </IconButton>
                  </Tooltip>
                </div>
              </AccordionSummary>
              <AccordionDetails
                sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                <CategoriesList
                  key={Math.random()}
                  tickets={presentationTickets}
                  matchName={presentationAccordionId}
                  matchId={presentationAccordionId}
                />
              </AccordionDetails>
              <ZipLoader
                open={downloading[presentationAccordionId] === true}
                numberOfTickets={presentationTickets?.length || 0}
              />
            </Accordion>
          ) : undefined}
          {matches.map((m) => (
            <Accordion
              className="tickets__accordion"
              expanded={expanded === m.id}
              onChange={handleChange(m.id || "")}
              key={m.id}>
              <AccordionSummary sx={{ display: "flex", justifyContent: "space-between" }} expandIcon={<ExpandMore />}>
                <div className="tickets__match__container">
                  <div className="tickets__match__container__logo">
                    <img src={m.opponent?.logo} height={50} alt={`Logo de l'équipe ${m.opponent?.name}`} />
                  </div>
                  <div className="tickets__match__container__infos">
                    <h6 className="tickets__match__container__infos__title">{m.title}</h6>
                    <div className="tickets__match__container__infos__body">
                      {m.time === "0h00"
                        ? t("dateFormatWithoutHour", { replace: { date: formatLongDate(m.date || "", true) } })
                        : t("dateFormat", {
                            replace: { date: formatLongDate(m.date || "", true), hour: m.time || "0H00" },
                          })}
                    </div>
                  </div>
                  <div className="tickets__match__container__tickets">
                    {t("ticket", {
                      count: tickets.filter((ticket) => ticket.matchId === m.id).length,
                    })}
                  </div>
                  {tickets.filter((ticket) => ticket.matchId === m.id).length > 10 ? undefined : (
                    <Tooltip title={t("downloadMatchTickets")} arrow>
                      <IconButton
                        onClick={(e) => dlTickets(e, m)}
                        disabled={
                          tickets.filter((ticket) => ticket.matchId === m.id).length === 0 || downloading[m.id]
                        }>
                        <Download />
                      </IconButton>
                    </Tooltip>
                  )}
                </div>
              </AccordionSummary>
              <AccordionDetails
                sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                <CategoriesList
                  key={Math.random()}
                  tickets={tickets.filter((ticket) => ticket.matchId === m.id)}
                  matchName={m.title || ""}
                  matchId={m.id}
                />
              </AccordionDetails>
              <ZipLoader
                open={downloading[m.id] === true}
                numberOfTickets={tickets.filter((ticket) => ticket.matchId === m.id).length}
              />
            </Accordion>
          ))}
        </>
      )}
    </div>
  );
};

export default Tickets;

import { FormControl, InputLabel, OutlinedInput, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import AbButton from "../components/AbButton";
import AbCenterCard from "../components/AbCenterCard";
import AbCenteredContent from "../components/AbCenteredContent";
import AbDownloadApp from "../components/AbDownloadApp";
import { postUserCorrelationCheck, postUserSetPassword } from "../data/Api";
import { User } from "../data/types/ApiTypes";

const UserPasswordPage: FC = () => {
  const [loading, setLoading] = useState(true);
  const [unauthorized, setUnauthorized] = useState(false);
  const [sending, setSending] = useState(false);
  const [password, setPassword] = useState("");
  const [passwordVerif, setPasswordVerif] = useState("");
  const [validUser, setValidUser] = useState<User | null>(null);
  const { userId, correlationId } = useParams() as {
    userId: string;
    correlationId: string;
  };
  const { t } = useTranslation("global");
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    (async () => {
      setLoading(true);
      const result = await postUserCorrelationCheck(userId, correlationId);
      if (typeof result === "number") setUnauthorized(true);
      else setUnauthorized(false);
      setLoading(false);
    })();
  }, [userId, correlationId]);

  const trySetPassword = async (): Promise<void> => {
    // Check password verif
    if (password !== passwordVerif) {
      enqueueSnackbar(t("setPassword.passwordVerifWrong"), { variant: "error" });
      return;
    }
    // Check password strength
    const mediumRegex = /^(((?=.*[a-z])(?=.*[A-Z]))((?=.*[A-Z])(?=.*[0-9])))(?=.{8,})/;
    if (!mediumRegex.test(password)) {
      enqueueSnackbar(t("setPassword.passwordStrength"), { variant: "error" });
      return;
    }
    // Send password to API
    setSending(true);
    const user = await postUserSetPassword(userId, correlationId, password);
    if (typeof user === "number") {
      setUnauthorized(true);
    } else {
      setValidUser(user);
    }
    setSending(false);
  };

  return (
    <div className="App">
      {loading ? (
        <AbCenteredContent loading loadingSize={48} loadingColor="inherit" />
      ) : (
        <AbCenterCard>
          {unauthorized ? (
            <Typography variant="h6">{t("setPassword.unauthorized")}</Typography>
          ) : validUser ? (
            <>
              <Typography variant="h6" className="margin-bottom">
                {t("setPassword.confirmation", {
                  replace: { username: `${validUser.firstName || ""} ${validUser.lastName || ""}` },
                })}
              </Typography>
              <AbDownloadApp />
            </>
          ) : (
            <div className="sign-in-form">
              <Typography variant="h6" className="margin-bottom">
                {t("setPassword.label")}
              </Typography>
              <Typography variant="subtitle2" className="big-margin-bottom">
                {t("setPassword.passwordAccept")}
              </Typography>
              <FormControl variant="outlined" className="big-margin-bottom" fullWidth>
                <InputLabel htmlFor="outlined-adornment-password">{t("setPassword.password")}</InputLabel>
                <OutlinedInput
                  id="outlined-adornment-password"
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  label={t("setPassword.password")}
                />
              </FormControl>
              <FormControl variant="outlined" className="big-margin-bottom" fullWidth>
                <InputLabel htmlFor="outlined-adornment-password">{t("setPassword.passwordVerif")}</InputLabel>
                <OutlinedInput
                  id="outlined-adornment-password"
                  type="password"
                  value={passwordVerif}
                  onChange={(e) => setPasswordVerif(e.target.value)}
                  label={t("setPassword.passwordVerif")}
                />
              </FormControl>
              <AbButton
                label={t("setPassword.send")}
                fullWidth
                isLoading={sending}
                onClick={trySetPassword}
                disabled={password.length === 0 || passwordVerif.length === 0}
              />
            </div>
          )}
        </AbCenterCard>
      )}
    </div>
  );
};

export default UserPasswordPage;

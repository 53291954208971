import axios, { AxiosError } from "axios";
import { log } from "./Logger";
import ApiActions from "./types/ApiActions";
import { AppConfig, AuthResponse, PartnerWebData, User } from "./types/ApiTypes";

const APP_ID = "AB_PARTNERS_WEB";

const API_URL = process.env.REACT_APP_API || "";

const logAndNumber = (retValue: number, ...args: unknown[]): number => {
  log.error(...args);
  return retValue;
};

const send = async <T>(
  action: string,
  token?: string,
  data?: unknown,
  isBlob?: boolean,
  getError = false,
): Promise<T | number> => {
  try {
    const result = await axios({
      url: API_URL,
      data,
      method: "POST",
      responseType: isBlob ? "blob" : undefined,
      headers: {
        "content-type": "application/json",
        "app-id": APP_ID,
        "api-action": action,
        ...(token ? { authorization: `Bearer ${token}` } : {}),
      },
    });
    if (result.status !== 200) return logAndNumber(result.status, "Error while posting data to API", result);
    return isBlob ? (new Blob([result.data]) as unknown as T) : (result.data as T);
  } catch (err) {
    const error = err as AxiosError;
    if (getError && typeof error.response?.data === "object") return error.response?.data as unknown as T;
    return logAndNumber(error.response?.status || 500, "Error while posting data to API", err);
  }
};

export const postAuthentication = async (login: string, password: string): Promise<AuthResponse | number> =>
  send<AuthResponse>(ApiActions.authenticate, undefined, { login, password }, false, true);

export const getNewToken = async (refreshToken: string): Promise<AuthResponse | number> =>
  send<AuthResponse>(ApiActions.refreshToken, refreshToken);

export const getAppConfig = async (): Promise<AppConfig | number> => send<AppConfig>(ApiActions.getConfig);

export const postUserCorrelationCheck = async (userId: string, correlationId: string): Promise<string | number> =>
  send<string>(ApiActions.userCheckCorrelation, undefined, { userId, correlationId });

export const postUserSetPassword = async (
  userId: string,
  correlationId: string,
  password: string,
): Promise<User | number> => send<User>(ApiActions.userSetPassword, undefined, { userId, correlationId, password });

export const getPartnersWebData = async (token: string): Promise<PartnerWebData | number> =>
  send<PartnerWebData>(ApiActions.getPartnersWebData, token);

export const getTicketPdfBlob = async (token: string, ticketId: number): Promise<Blob | number> =>
  send<Blob>(ApiActions.getTicketPdf, token, { ticketId }, true);

export const getTicketsArchiveBlob = async (
  token: string,
  matchId: string,
  category?: string,
): Promise<Blob | number> => send<Blob>(ApiActions.allTicketsForMatch, token, { matchId, category }, true);

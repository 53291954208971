import { Typography } from "@mui/material";
import { FC, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import AbCenteredContent from "../components/AbCenteredContent";
import { DataContext } from "../data/contexts/DataContext";
import { formatDate } from "../data/Converters";
import { ConditionsType } from "../data/types/ApiTypes";

const Conditions: FC<{ conditionsType: ConditionsType }> = ({ conditionsType }) => {
  const [loading, setLoading] = useState(true);
  const { conditions, initApp } = useContext(DataContext);
  const { t } = useTranslation("global");

  useEffect(() => {
    (async () => {
      setLoading(true);
      await initApp();
      setLoading(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const finalConditions = conditions.find((c) => c.type === conditionsType);

  return (
    <div className="App white">
      {loading ? (
        <AbCenteredContent loading />
      ) : !finalConditions ? (
        <AbCenteredContent errorText={t("conditions.notFound")} />
      ) : (
        <div>
          <Typography variant="h5" className="center margin-top">
            {t("appName")} -{" "}
            {t("conditions.conditionsOf", {
              replace: {
                conditionsName: t(`conditions.${conditionsType}`),
                version: formatDate(finalConditions.version || 0 * 1000),
              },
            })}
          </Typography>
          <Typography
            className="margin-bottom ql-editor"
            dangerouslySetInnerHTML={{ __html: finalConditions.body || "" }}
          />
        </div>
      )}
    </div>
  );
};

export default Conditions;

import { AccountCircle } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { FC, useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import SignIn from "../components/SignIn";
import Tickets from "../components/Tickets";
import UserMenu from "../components/UserMenu";
import { AuthContext } from "../data/contexts/AuthContext";
import { DataContext } from "../data/contexts/DataContext";
import "../styles/hero.css";

const TicketsPage: FC = () => {
  const { auth } = useContext(AuthContext);
  const { getPartnerWebData } = useContext(DataContext);
  const { signOut } = useContext(AuthContext);
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const closeMenu = (): void => {
    setAnchorEl(null);
  };
  const navIfRelevant = (path: string): void => {
    if (path !== pathname) {
      navigate(path);
    }
  };

  useEffect(() => {
    if (auth) getPartnerWebData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth !== null]);
  const heroPictureDesktopUrl =
    "https://res.cloudinary.com/abrugby/image/upload/t_HeroDesktop/v1/appli-b-to-b/push-billetterie-partenaires/PUSH_BILLETTERIE_PARTENAIRES";
  const heroPictureMobileUrl =
    "https://res.cloudinary.com/abrugby/image/upload/t_HeroMobile/v1/appli-b-to-b/push-billetterie-partenaires/PUSH_BILLETTERIE_PARTENAIRES";
  return (
    <div>
      <div className="hero__container">
        <picture className="hero__container__bg">
          <source srcSet={heroPictureDesktopUrl} media="(min-width: 768px)" />
          <img className="hero__container__bg" src={heroPictureMobileUrl} alt="hero mobile" />
        </picture>
        <div className="hero__box">
          {auth !== null ? (
            <>
              <IconButton
                className="hero__box__account"
                edge="end"
                aria-controls="auth-menu"
                aria-haspopup="true"
                onClick={(evt) => setAnchorEl(evt.currentTarget)}>
                <AccountCircle fontSize="large" />
              </IconButton>
              <UserMenu anchorEl={anchorEl} close={closeMenu} nav={() => navIfRelevant("/billets")} signOut={signOut} />
            </>
          ) : undefined}
        </div>
      </div>
      {auth === null ? <SignIn /> : <Tickets />}
    </div>
  );
};

export default TicketsPage;
